import React from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles";
import { mapDispatchToProps, mapStateToProps } from "../../fredux/ActionMaker";
import * as CONST from "../../util/Const";

import { hasPermission, has_tab_permission } from '../../util/ACL';
import Paper from '@material-ui/core/Paper';
import { Grid } from '@material-ui/core';
import Badge from '@material-ui/core/Badge';



const style = theme => ({
  active: {
    color: '#3f51b5',
    minWidth: 120,
    // width: 120,
    variant: "fullWidth"
  },
  inActive: {
    backgroundColor: 'rgba(255, 255, 255, 0.12)',
    minWidth: 120,
    // width: 120,
  }
});

const renderIcon = (props) => {
  const profilePic = props.profile.profilePicture;

  if (profilePic && profilePic !== '') {
    return (
      <img
        src={profilePic}
        style={{ width: 40, height: 40, borderRadius: '50%' }}
        alt="User Avatar"
      />
    );
  } else {
    return <AccountCircleIcon style={{ fontSize: 40 }} />;
  }
};

class ProfileTabs extends React.Component {
  constructor(props) {
    super(props)
  }

  getActiveColor = (tabName) => {
    if (tabName === this.props.currentTab) {
      return (this.props.classes.active)
    }
    return (this.props.classes.inActive)

  }

  /**
   * Default tab landing router/ui api.
   * @param {*} event 
   * @param {*} newValue 
   */
  handleTabChange = (event, newValue) => {
    let url = ''
    if (newValue == CONST.TAB_PROFILE) {
      url = `${CONST.ROUTE_MY_ACCOUNT_URL}${this.props.profile.id}`
      this.props.updateCurrentTab(newValue)
      this.props.history.push(url)
    }
    let urlPermission = has_tab_permission(this.props, newValue)
    if (!!!urlPermission.urlRoute) {
      //Unauthorize url redirect to home.
      return CONST.ROUTE_DASHBOARD_URL
    }
    url = urlPermission.urlRoute

    this.props.updateCurrentTab(newValue)
    this.props.history.push(url)

    // this.props.updateCurrentTab(newValue)
    // let url = ''
    // switch (newValue) {
    //   case CONST.TAB_DASHBOARD:
    //       url = CONST.ROUTE_DASHBOARD_URL
    //       break;
    //   case CONST.TAB_REPORT:
    //       url = CONST.ROUTE_REPORT_URL
    //       break;
    //   case CONST.TAB_SAFETY_AND_COMPLIANCE:
    //         url = CONST.ROUTE_SAFETY_AND_COMPLIANCE_URL
    //         break;
    //   case CONST.TAB_ACCOUNTING:
    //       url = CONST.ROUTE_ACCOUNTING_URL
    //       break;
    //   case CONST.TAB_EXPENSE:
    //         url = CONST.ROUTE_EXPENSE_TAB_URL
    //         break;
    //   case CONST.TAB_FLEET:
    //       url = CONST.ROUTE_FLEET_TAB_URL
    //       break;
    //   case CONST.TAB_ADMIN:
    //       url = CONST.ROUTE_ADMIN_URL
    //       break;
    //   case CONST.TAB_PROFILE:
    //       const profile = this.props.profile
    //       url = `${CONST.ROUTE_MY_ACCOUNT_URL}${profile.id}`
    //       break;
    // }
    // this.props.history.push(url)

  };
  render() {
    return (
      <Grid container justify="flex-end">
        <Tabs
          value={this.props.currentTab}
          indicatorColor="primary"
          textColor="primary"
          onChange={this.handleTabChange}
        >
          {!!(has_tab_permission(this.props, CONST.TAB_DASHBOARD).urlRoute) && <Tab label="Dashboard" value={CONST.TAB_DASHBOARD}
            className={this.getActiveColor(CONST.TAB_DASHBOARD)} />}
          {/* {hasPermission(this.props, CONST.ROUTE_DASHBOARD_URL) && <Tab label="Dashboard" value={CONST.TAB_DASHBOARD} 
                 className={this.getActiveColor(CONST.TAB_DASHBOARD)}/>} */}
          {!!(has_tab_permission(this.props, CONST.TAB_REPORT).urlRoute) && <Tab label="Report" value={CONST.TAB_REPORT}
            className={this.getActiveColor(CONST.TAB_REPORT)} />}
          {/* {hasPermission(this.props, CONST.ROUTE_REPORT_URL) && <Tab label="Report" value={CONST.TAB_REPORT} 
               className={this.getActiveColor(CONST.TAB_REPORT)} />} */}

          {!!(has_tab_permission(this.props, CONST.TAB_ACCOUNTING).urlRoute) && <Tab label="Finance" value={CONST.TAB_ACCOUNTING}
            className={this.getActiveColor(CONST.TAB_ACCOUNTING)} />}
          {/* {hasPermission(this.props, CONST.ROUTE_ACCOUNTING_URL) && <Tab label="Finance" value={CONST.TAB_ACCOUNTING}
               className={this.getActiveColor(CONST.TAB_ACCOUNTING)}/>}
           */}
          {!!(has_tab_permission(this.props, CONST.TAB_SAFETY_AND_COMPLIANCE).urlRoute) && <Tab label="Safety & Compliance" value={CONST.TAB_SAFETY_AND_COMPLIANCE}
            className={this.getActiveColor(CONST.TAB_SAFETY_AND_COMPLIANCE)} />}

          {/* {hasPermission(this.props, CONST.TAB_SAFETY_AND_COMPLIANCE) && <Tab label="Safety & Compliance" value={CONST.TAB_SAFETY_AND_COMPLIANCE}
               className={this.getActiveColor(CONST.TAB_SAFETY_AND_COMPLIANCE)}/>} */}

          {!!(has_tab_permission(this.props, CONST.TAB_EXPENSE).urlRoute) && <Tab label="Expense" value={CONST.TAB_EXPENSE}
            className={this.getActiveColor(CONST.TAB_EXPENSE)} />}

          {/* {hasPermission(this.props, CONST.TAB_EXPENSE) && <Tab label="Expense" value={CONST.TAB_EXPENSE}
               className={this.getActiveColor(CONST.TAB_EXPENSE)}/>} */}

          {!!(has_tab_permission(this.props, CONST.TAB_FLEET).urlRoute) && <Tab label="Fleet" value={CONST.TAB_FLEET}
            className={this.getActiveColor(CONST.TAB_FLEET)} />}
          {/* {hasPermission(this.props, CONST.ROUTE_FLEET_TAB_URL) && <Tab label="Fleet" value={CONST.TAB_FLEET}
               className={this.getActiveColor(CONST.TAB_FLEET)}/>} */}

          {!!(has_tab_permission(this.props, CONST.TAB_ADMIN).urlRoute) && <Tab label="Admin" value={CONST.TAB_ADMIN}
            className={this.getActiveColor(CONST.TAB_ADMIN)} />}

          {/* {hasPermission(this.props, CONST.ROUTE_ADMIN_URL) && <Tab label="Admin" value={CONST.TAB_ADMIN} 
              className={this.getActiveColor(CONST.TAB_ADMIN)}/>} */}

          {/* {!!(has_tab_permission(this.props, CONST.TAB_DRIVER_APP).urlRoute) && <Tab label="Driver App" value={CONST.TAB_DRIVER_APP} 
              className={this.getActiveColor(CONST.TAB_DRIVER_APP)}/>} */}
          {!!(has_tab_permission(this.props, CONST.TAB_DRIVER_APP).urlRoute) && (
            <Tab
              label={
                this.props.driverAppUploadCount > 0 ? (
                  <Badge badgeContent={this.props.driverAppUploadCount} color="secondary">
                    Driver App
                  </Badge>
                ) : (
                  "Driver App"
                )
              }
              value={CONST.TAB_DRIVER_APP}
              className={this.getActiveColor(CONST.TAB_DRIVER_APP)}
            />
          )}

          {<Tab icon={renderIcon(this.props)}
            value={CONST.TAB_PROFILE} className={this.getActiveColor(CONST.TAB_PROFILE)} />}
        </Tabs>
      </Grid>
    )
  }
}



export default withStyles(style)(connect(
  mapStateToProps, mapDispatchToProps)(ProfileTabs))
