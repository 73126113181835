import React, { Component } from 'react';
import { withStyles } from "@material-ui/core/styles";
import FRouterLink, { FRouterAddLink } from '../widgets/FRouterLink';
import {
  API_DRIVER_EMPLOYMENT_APPLICATION_URL,
  CHOICE_DRIVER_EMPLOYMENT_APPLICATION, TAB_DRIVER_APP,
  DRIVER_APP_EMPLOYMENT_APPLICATION, ROUTE_DRIVER_APP_EMPLOYMENT_APPLICATION_URL, getModuleTitle
} from '../../util/Const';
import FListComponent from '../../util/FListComponent';
import { connect } from "react-redux"
import { mapStateToProps, mapDispatchToProps } from '../../fredux/ActionMaker';
import FTableAction, { ACTION_TABLE_ACTIVATE, ACTION_TABLE_DEACTIVATE, ACTION_TABLE_DELETE_ROW } from '../widgets/FTableAction';
import FMaterialTable from '../widgets/FMaterialTable';
import BlockIcon from '@material-ui/icons/Block';
import { getDriverEmploymentVerificationReport, getDriverHiringProcessStatusIcon, getEmploymentApplicationIcon, getEmploymentHistoryForDriverApplicationView, getEmploymentHistoryResponseForDriverApplicationView, getStateIcon, showPhoneUserView } from '../../util/icon_util';
import { Link } from '@material-ui/core';
import FAttachment from '../widgets/FAttachment';
import { UPDATE } from '../../util/FAPI';
import FMessage, { getErrroMessage, getSuccessMessage, showErroNotification, showSuccessNotification } from '../widgets/FMessage';
import LinearProgress from '@material-ui/core/LinearProgress';
import FLinearProgressBar from '../widgets/FLinearProgressBar';
import { has_edit_permission } from '../../util/ACL';

const styles = theme => ({
  smallAvatar: {
    width: 10,
    height: 10,
    border: `2px solid ${theme.palette.background.paper}`,

  },
});

// Action displayed on the menu
const ACTION_ACCEPT_APPLICATION = 'Accept Application'
const ACTION_REQUEST_DRIVER_TO_MAKE_UPDATES = 'Request Application Updates'
const ACTION_INVITE_DRIVER_TO_APPLY_VIA_APP = 'Invite Driver to Apply'
const ACTION_INTIATE_APPLICATION_BACKGROUND_VERIFICATION_PROCESS = 'Start Employment Verification & Background Check'
const ACTION_REQUEST_DRIVER_SAFETY_AND_PREFORMANCE_CONSENT = 'Request Safety Consent'
const ACTION_SEND_EMPLOYMENT_VERIFICATION_REPORT = 'Generate Driver Snapshot Report';
const ACTTION_START_OVER_EMPLOYMENT_VERIFICATION_PROCESS = 'Start Over Verification'
const ACTION_REQUEST_DRIVER_POST_HIRE_SAFETY_AND_COMPLIANCE_AGREEMENT = 'Request Driver to Sign Agreement'; // 'Send Driver Safety & Compliance Agreement for Signing';

const ACTION_REQUEST_DRIVER_POST_HIRE_DOCUMENT_COLLECTION_REQUEST = 'Request Driver to Submit Required Documents'; // 'Send Driver Post-Hire Document Collection Request';


// Individual requests
const ACTION_REQUEST_DRIVER_CDL = 'Request Driver to Submit CDL';
const ACTION_REQUEST_DRIVER_MVR = 'Request Driver to Submit MVR';
const ACTION_REQUEST_DRIVER_MEDICAL_CARD = 'Request Driver to Submit Medical Card';
const ACTION_REQUEST_DRIVER_W9 = 'Request Driver to Submit W9';


// Individual POLICY requests
const ACTION_REQUEST_DRIVER_DISCIPLINARY_ACTION_POLICY = 'Request Driver to Sign Disciplinary Action Policy';
const ACTION_REQUEST_DRIVER_HOS_POLICY = 'Request Driver to Sign Hours of Service (HOS) Policy';
const ACTION_REQUEST_DRIVER_DRUG_AND_ALCOHOL_POLICY = 'Request Driver to Sign Drug and Alcohol Policy';
const ACTION_REQUEST_DRIVER_HIRING_POLICY = 'Request Driver to Sign Carrier Hiring Policy';
const ACTION_REQUEST_VEHICLE_OPERATOR_AND_CARRIER_LEASE_AGREEMENT_POLICY = 'Request Driver to Sign Leasing Agreement';

// State
const DRAFT = 'DRAFT'
const PENDING_BACKGROUND_VERIFICATION = 'PENDING_BACKGROUND_VERIFICATION'
const APPLICATION_PROCESS_COMPLETE = 'APPLICATION_PROCESS_COMPLETE'
const DRIVER_APPLICATION_READY_FOR_REVIEW = 'DRIVER_APPLICATION_READY_FOR_REVIEW'
const PENDING_DRIVER_ACTION = 'PENDING_DRIVER_ACTION'
const READY_FOR_BACKGROUND_VERIFICATION = 'READY_FOR_BACKGROUND_VERIFICATION'
const APPLICATION_PROCESS_COMPLETE_WITH_UNRESPONSIVE_EMPLOYER = 'APPLICATION_PROCESS_COMPLETE_WITH_UNRESPONSIVE_EMPLOYER'
const PENDING_DRIVER_CONSENT = 'PENDING_DRIVER_CONSENT'
const NO_EMPLOYMENT_HISTORY = 'NO_EMPLOYMENT_HISTORY'
const DENIED_REJECTD_OR_UN_VERIFIABLE = 'DENIED_REJECTD_OR_UN_VERIFIABLE'


const DRIVER_HIRING_PROCESS_STATE_MACHINE_APP_USER = {
  [DRAFT]: [ACTION_INVITE_DRIVER_TO_APPLY_VIA_APP],
  [READY_FOR_BACKGROUND_VERIFICATION]: [ACTION_INTIATE_APPLICATION_BACKGROUND_VERIFICATION_PROCESS, ACTION_SEND_EMPLOYMENT_VERIFICATION_REPORT],
  [PENDING_BACKGROUND_VERIFICATION]: [ACTION_SEND_EMPLOYMENT_VERIFICATION_REPORT],
  [DRIVER_APPLICATION_READY_FOR_REVIEW]: [ACTION_ACCEPT_APPLICATION],
  [PENDING_DRIVER_ACTION]: [ACTION_ACCEPT_APPLICATION],
  [PENDING_DRIVER_CONSENT]: [ACTION_ACCEPT_APPLICATION],
  [APPLICATION_PROCESS_COMPLETE]: [ACTION_SEND_EMPLOYMENT_VERIFICATION_REPORT],
  [NO_EMPLOYMENT_HISTORY]: [ACTION_SEND_EMPLOYMENT_VERIFICATION_REPORT, ACTTION_START_OVER_EMPLOYMENT_VERIFICATION_PROCESS],
  [APPLICATION_PROCESS_COMPLETE_WITH_UNRESPONSIVE_EMPLOYER]: [ACTION_SEND_EMPLOYMENT_VERIFICATION_REPORT, ACTTION_START_OVER_EMPLOYMENT_VERIFICATION_PROCESS],
  [DENIED_REJECTD_OR_UN_VERIFIABLE]: [ACTION_SEND_EMPLOYMENT_VERIFICATION_REPORT, ACTTION_START_OVER_EMPLOYMENT_VERIFICATION_PROCESS],
};

const DRIVER_HIRING_PROCESS_STATE_MACHINE_NOT_APP_USER = {
  [DRAFT]: [ACTION_INVITE_DRIVER_TO_APPLY_VIA_APP],
  [PENDING_BACKGROUND_VERIFICATION]: [ACTION_INVITE_DRIVER_TO_APPLY_VIA_APP],
  [APPLICATION_PROCESS_COMPLETE]: [ACTION_INVITE_DRIVER_TO_APPLY_VIA_APP],
  [DRIVER_APPLICATION_READY_FOR_REVIEW]: [ACTION_INVITE_DRIVER_TO_APPLY_VIA_APP],
  [PENDING_DRIVER_ACTION]: [ACTION_INVITE_DRIVER_TO_APPLY_VIA_APP]
};

let COLLECTION_DOCS = [
  ACTION_REQUEST_DRIVER_CDL, ACTION_REQUEST_DRIVER_MVR, ACTION_REQUEST_DRIVER_MEDICAL_CARD, ACTION_REQUEST_DRIVER_W9,
  ACTION_REQUEST_DRIVER_DISCIPLINARY_ACTION_POLICY, ACTION_REQUEST_DRIVER_HOS_POLICY, ACTION_REQUEST_DRIVER_DRUG_AND_ALCOHOL_POLICY,
  ACTION_REQUEST_DRIVER_HIRING_POLICY, ACTION_REQUEST_VEHICLE_OPERATOR_AND_CARRIER_LEASE_AGREEMENT_POLICY
]

let PARENT_ALL_WORKFLOWS = [ACTION_REQUEST_DRIVER_POST_HIRE_SAFETY_AND_COMPLIANCE_AGREEMENT, ACTION_REQUEST_DRIVER_POST_HIRE_DOCUMENT_COLLECTION_REQUEST] 
// COLLECTION_DOCS = []

class DriverEmploymentApplicatoins extends FListComponent {
  constructor(props) {
    super(props, API_DRIVER_EMPLOYMENT_APPLICATION_URL, CHOICE_DRIVER_EMPLOYMENT_APPLICATION)
    this.currentTitle = 'Employment Applications'
    this.currentTab = TAB_DRIVER_APP
    this.routeUrl = ROUTE_DRIVER_APP_EMPLOYMENT_APPLICATION_URL
    this.policyID = DRIVER_APP_EMPLOYMENT_APPLICATION
    this.includeDeleteAction = false
    this.includeDriverHiringProcessStatus = true
  }

  getActions = (rowData, target, ...actions) => {
    // let _actions = PARENT_ALL_WORKFLOWS
    let _actions = COLLECTION_DOCS
    let profileHasPermission = (has_edit_permission(this.props.profile, DRIVER_APP_EMPLOYMENT_APPLICATION))

    if (!!this.includeDriverHiringProcessStatus && !!rowData.driverHiringProcessStatus) {
      if (!!rowData.is_profile_app_user && !!profileHasPermission) {
        _actions = _actions.concat(DRIVER_HIRING_PROCESS_STATE_MACHINE_APP_USER[rowData.driverHiringProcessStatus])
      }
      if (!!!rowData.is_profile_app_user && !!profileHasPermission) {
        _actions = _actions.concat(DRIVER_HIRING_PROCESS_STATE_MACHINE_NOT_APP_USER[rowData.driverHiringProcessStatus])
      }
    }
    return _actions
  }

  onTableAction = (action, rowData) => {
    let data = { id: rowData.id }
    let url = this.getListURL() + rowData.id + '/event/'
    switch (action) {

      case ACTION_ACCEPT_APPLICATION:
        data = {
          ...data,
          ...{ eventType: 'COMPLETE_DRIVER_APPLICATION' }
        }
        this.actionHandler(url, data)
        break;
      case ACTION_REQUEST_DRIVER_TO_MAKE_UPDATES:
        data = {
          ...data,
          ...{ eventType: 'REQUEST_DRIVER_TO_UPDATE_APPLICATION' }
        }
        this.actionHandler(url, data)
        break;
      case ACTION_INTIATE_APPLICATION_BACKGROUND_VERIFICATION_PROCESS:
        console.log('Action Again ' + action)
        data = {
          ...data,
          ...{ eventType: 'INTIATE_APPLICATION_BACKGROUND_VERIFICATION_PROCESS' }
        }
        this.actionHandler(url, data)
        break;
      case ACTION_INVITE_DRIVER_TO_APPLY_VIA_APP:
        data = {
          ...data,
          ...{ eventType: 'INVITE_DRIVER_TO_APPLY_VIA_APP' }
        }
        this.actionHandler(url, data)
        break;
      case ACTION_REQUEST_DRIVER_SAFETY_AND_PREFORMANCE_CONSENT:
        data = {
          ...data,
          ...{ eventType: 'REQUEST_DRIVER_FOR_SAFETY_AND_PREFORMANCE_CONSENT' }
        }
        this.actionHandler(url, data)
        break;
      case ACTION_SEND_EMPLOYMENT_VERIFICATION_REPORT:
        data = {
          ...data,
          ...{ eventType: 'SEND_EMPLOYMENT_VERIFICATION_REPORT' }
        }
        this.actionHandler(url, data)
        break;
      case ACTTION_START_OVER_EMPLOYMENT_VERIFICATION_PROCESS:
        data = {
          ...data,
          ...{ eventType: 'START_OVER_EMPLOYMENT_VERIFICATION_PROCESS' }
        }
        this.actionHandler(url, data)
        break;
      case ACTION_REQUEST_DRIVER_POST_HIRE_SAFETY_AND_COMPLIANCE_AGREEMENT:
        data = {
          ...data,
          ...{ eventType: 'REQUEST_DRIVER_POST_HIRE_SAFETY_AND_COMPLIANCE_AGREEMENT' }
        }
        this.actionHandler(url, data)
        break;
      case ACTION_REQUEST_DRIVER_POST_HIRE_DOCUMENT_COLLECTION_REQUEST:
        data = {
          ...data,
          ...{ eventType: 'REQUEST_DRIVER_POST_HIRE_DOCUMENT_COLLECTION_REQUEST' }
        }
        this.actionHandler(url, data)
        break;

      case ACTION_REQUEST_DRIVER_W9:
        data = {
          ...data,
          ...{ eventType: 'REQUEST_DRIVER_DOCUMENT_SUBMIT_DOCUMENT_W9' }
        }
        this.actionHandler(url, data)
        break;
      case ACTION_REQUEST_DRIVER_CDL:
        data = {
          ...data,
          ...{ eventType: 'REQUEST_DRIVER_DOCUMENT_SUBMIT_DOCUMENT_CDL' }
        }
        this.actionHandler(url, data)
        break;
      case ACTION_REQUEST_DRIVER_MVR:
        data = {
          ...data,
          ...{ eventType: 'REQUEST_DRIVER_DOCUMENT_SUBMIT_DOCUMENT_MVR' }
        }
        this.actionHandler(url, data)
        break;
      case ACTION_REQUEST_DRIVER_MEDICAL_CARD:
        data = {
          ...data,
          ...{ eventType: 'REQUEST_DRIVER_DOCUMENT_SUBMIT_DOCUMENT_MEDICAL_CARD' }
        }
        this.actionHandler(url, data)
        break;

      case ACTION_REQUEST_DRIVER_DISCIPLINARY_ACTION_POLICY:
        data = {
          ...data,
          ...{ eventType: 'REQUEST_DRIVER_DISCIPLINARY_ACTION_POLICY' }
        }
        this.actionHandler(url, data)
        break;
      case ACTION_REQUEST_DRIVER_HOS_POLICY:
        data = {
          ...data,
          ...{ eventType: 'REQUEST_DRIVER_HOS_POLICY' }
        }
        this.actionHandler(url, data)
        break;
      case ACTION_REQUEST_DRIVER_DRUG_AND_ALCOHOL_POLICY:
        data = {
          ...data,
          ...{ eventType: 'REQUEST_DRIVER_DRUG_AND_ALCOHOL_POLICY' }
        }
        this.actionHandler(url, data)
        break;
      case ACTION_REQUEST_DRIVER_HIRING_POLICY:
        data = {
          ...data,
          ...{ eventType: 'REQUEST_DRIVER_HIRING_POLICY' }
        }
        this.actionHandler(url, data)
        break;
      case ACTION_REQUEST_VEHICLE_OPERATOR_AND_CARRIER_LEASE_AGREEMENT_POLICY:
        data = {
          ...data,
          ...{ eventType: 'REQUEST_VEHICLE_OPERATOR_AND_CARRIER_LEASE_AGREEMENT_POLICY' }
        }
        this.actionHandler(url, data)
        break;
    }
  }


  actionHandler = (url, data) => {
    const that = this
    this.setState({ isLoading: true })
    UPDATE(url, 'patch', this.getFormData(data)).then(res => {
      // TODO (Estifanos) This should only update the list entry
      // this.fetchListData();
      this.setState({ isLoading: false })
      this.setState({ currentAction: 'update' }, () => {
        that.tableRef.current.onQueryChange();
      })
      showSuccessNotification(res, getModuleTitle(this.state.module, 'SAVE'))
      that.props.updateServerSuccess && that.props.updateServerSuccess({ msg: getSuccessMessage(res) })

    }).catch(err => {
      this.setState({ isLoading: false })
      showErroNotification(err, getModuleTitle(this.state.module, 'SAVE'))
    })
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        <FMaterialTable
          isLoading={this.state.isLoading}
          tableRef={this.tableRef}
          columns={[
            // {
            //   title: "Application",
            //   render: rowData => {
            //     const { applicationID, driverProfile } = rowData;
            //     const driverAccessID = driverProfile ? driverProfile.driver.driverAccessID : '';
            //     const url = `http://localhost:8000/api/zcr/driver-emp-application/?emp=${applicationID}&dri=${driverAccessID}`;

            //     return (
            //       <React.Fragment>
            //         <div style={{ display: 'inline-flex' }}>
            //           <a href={url} target="_blank" rel="noopener noreferrer">
            //             {url}
            //           </a>
            //         </div>
            //       </React.Fragment>
            //     );
            //   }
            // },
            // {
            //   title: "Policy Request",
            //   render: rowData => {
            //     const { applicationID, driverProfile } = rowData;
            //     const driverAccessID = driverProfile ? driverProfile.driver.driverAccessID : '';
            //     const url = `http://localhost:8000/api/zcr/driver-saf-comp-policy/?ref=${applicationID}&dri=${driverAccessID}`;

            //     return (
            //       <React.Fragment>
            //         <div style={{ display: 'inline-flex' }}>
            //           <a href={url} target="_blank" rel="noopener noreferrer">
            //             {url}
            //           </a>
            //         </div>
            //       </React.Fragment>
            //     );
            //   }
            // },
            {
              title: 'App User',
              width: '20%',
              render: rowData => {
                return (
                  <React.Fragment>
                    <div style={{ display: 'inline-flex' }}>
                      {showPhoneUserView(rowData)}
                    </div>
                  </React.Fragment>
                );
              }
            },
            {
              title: 'Driver / Prospect Driver',
              render: this.getProfileDriverLink
            },
            {
              title: 'Driver Application',
              render: rowData => {
                if (!rowData.is_profile_app_user) {
                  return null;
                }

                const hasEmploymentApplication = rowData.applicationFile || false;
                return (
                  hasEmploymentApplication ? (
                    <React.Fragment>
                      <div style={{ display: 'inline-flex' }}>
                        {getEmploymentApplicationIcon(rowData)}
                      </div>
                    </React.Fragment>
                  ) : null // Displays nothing if no employment application data
                );
              }
            },




            // {
            //   title: 'Employment Verifications Requests',
            //   render: rowData => {
            //     if (!rowData.is_profile_app_user) {
            //       return null; // Display nothing if not an app user
            //     }

            //     const employmentHistories = rowData.employment_histories || [];
            //     return (
            //       employmentHistories.length > 0 ? (
            //         <React.Fragment>
            //           <div style={{ display: 'inline-flex' }}>
            //             {getEmploymentHistoryForDriverApplicationView(employmentHistories)}
            //           </div>
            //         </React.Fragment>
            //       ) : null
            //     );
            //   }
            // },

            // {
            //   title: 'Employment Verifications Responses',
            //   render: rowData => {
            //     if (!rowData.is_profile_app_user) {
            //       return null; // Display nothing if not an app user
            //     }

            //     const employmentHistories = rowData.employment_histories || [];
            //     return (
            //       employmentHistories.length > 0 ? (
            //         <React.Fragment>
            //           <div style={{ display: 'inline-flex' }}>
            //             {getEmploymentHistoryResponseForDriverApplicationView(employmentHistories)}
            //           </div>
            //         </React.Fragment>
            //       ) : null
            //     );
            //   }
            // },

            {
              title: 'Verification Progress & Estimated Date',
              render: rowData => {
                if (!rowData?.is_profile_app_user) {
                  return null;
                }

                const progress = rowData.employment_application_verification_progress || {};
                const completedSteps = progress.completedSteps || 0;
                const totalSteps = progress.totalSteps || 0;
                const estimatedCompletionDate = progress.estimatedCompletionDate;

                return (
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    {/* Driver Hiring Status Icon */}
                    <div style={{ display: 'inline-flex', marginRight: 16 }}>
                      {getDriverHiringProcessStatusIcon(rowData)}
                    </div>

                    {/* Progress Bar with Percentage */}
                    {totalSteps > 0 && (
                      <div style={{ display: 'flex', alignItems: 'center', marginRight: 16 }}>
                        <FLinearProgressBar
                          completedSteps={completedSteps}
                          totalSteps={totalSteps}
                          style={{ height: 8, width: 150, marginRight: 8 }}
                        />
                        <span>{Math.round((completedSteps / totalSteps) * 100)}%</span>
                      </div>
                    )}

                    {/* Estimated Completion Date */}
                    {estimatedCompletionDate && (
                      <span style={{ fontSize: '1em', fontWeight: 'bold', color: '#333' }}>
                        ETA: {this.getPrettyDate(estimatedCompletionDate)}
                      </span>
                    )}
                  </div>
                );
              }
            },
            {
              title: 'FMCSA Report & Driver Submission',
              render: rowData => {
                if (!rowData.is_profile_app_user) {
                  return null;
                }

                return (
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    {/* Driver Employment Verification Report */}
                    <span style={{ fontSize: '1em', fontWeight: 'bold', color: '#333', marginRight: 16 }}>
                      {getDriverEmploymentVerificationReport(rowData)}
                    </span>

                  </div>
                );
              }
            }
            ,
            {
              title: 'Actions',
              render: rowData => {
                let actions = this.getActions(rowData, DRIVER_APP_EMPLOYMENT_APPLICATION)

                return (
                  <React.Fragment>
                    {!!actions.length && <FTableAction
                      actions={actions}
                      onTableAction={this.onTableAction}
                      rowData={rowData}
                    />
                    }
                    {!!!actions.length && <BlockIcon color="disabled" />}
                  </React.Fragment>
                )
              }
            },
          ]}
          // actions={
          //   [
          //     this.getAddNewModelActions()
          //   ]
          //  }
          options={{
            pageSize: this.state.pageSize,
            pageSizeOptions: this.state.pageSizeOptions,
            actionsColumnIndex: -1
          }}
          data={this.fetchData}
          title="Driver Employment Application"
          listErrCode={this.state.listErrCode}
        />
      </div>
    );
  }
}


export default (connect(mapStateToProps, mapDispatchToProps)(DriverEmploymentApplicatoins))
