import React, { Component } from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { Link } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

import ListItemText from '@material-ui/core/ListItemText';
import { mapDispatchToProps, mapStateToProps } from "../../fredux/ActionMaker";
import * as CONST from "../../util/Const";
import { connect } from 'react-redux'
import {withRouter} from 'react-router-dom';
import Badge from '@material-ui/core/Badge';



const style = theme => ({
  active: {
    color: 'blue',
    backgroundColor: 'rgb(245, 247, 248)'
  },
  inActive: {
    backgroundColor: '#fff',
  }
});

class FListItem extends Component {

  getActiveColor = () => {
    let pathName = this.props.location && this.props.location.pathname
    if (pathName.includes(this.props.to)){
       return (this.props.classes.active)
     }
     return (this.props.classes.inActive)
  }

  render() {
    const className = this.getActiveColor()
    if (!!this.props.disabled) {
      return ''
    }
    return (
       <ListItem 
          disabled={!!this.props.disabled}
          component={Link} to={this.props.to} 
          className={className}
          button>
          <ListItemIcon>
              {this.props.children}
          </ListItemIcon>
          <ListItemText 
          primary={
            this.props.totalCount > 0 ? (
              <Badge badgeContent={this.props.totalCount} color="secondary">
                {this.props.label}
              </Badge>
            ) : (
              this.props.label
            )
          } 
        />
      </ListItem>
      );
  }
}



export default withRouter(withStyles(style)(
  connect(
    mapStateToProps,
    mapDispatchToProps)(FListItem))
)

// export default withStyles(style)(connect(
//   mapStateToProps, mapDispatchToProps)(FListItem))

// export default withStyles(style)(FListItem)